import { useAuth0, Auth0ContextInterface } from "@auth0/auth0-react";

interface Users extends Auth0ContextInterface {
  user?: {
    sub: string;
    name: string;
    email: string;
    picture: string;
  };
}

export const useUser = (): Users => useAuth0();

export default useUser;
